<template>
  <article class="card">
    <div class="card__left">
      <template v-if="isLoadingData">
        <FontAwesomeIcon
          class="spinner"
          :icon="['fal', 'spinner-third']"
        />
      </template>
      <template v-else>
        {{ leftText }}
      </template>
    </div>
    <span class="card__separator"></span>
    <div class="card__right">{{ rightText }}</div>
  </article>
</template>

<script>
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

library.add(faSpinnerThird);

export default defineComponent({
  name: 'AnalyticsCard',
  components: { FontAwesomeIcon },
  props: {
    leftText: { type: String, required: true },
    rightText: { type: String, required: true },
    isLoadingData: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.card {
  width: 100%;
  border-radius: 30px;

  font-family: inherit;
  background: $dark-green;

  padding: 2rem 3rem;

  display: flex;
  justify-content: space-around;
  align-items: center;

  @include spacing-children("horizontal", 2rem);

  &__left,
  &__right {
    color: $alternate-green;
  }

  &__left {
    font-size: 3.5rem;

    .spinner {
      transition: transform 1s;
      animation: spin 0.8s forwards infinite;
    }
  }

  &__right {
    font-size: 1.8rem;
  }

  &__separator {
    height: 8rem;
    border-left: 2px solid $alternate-green;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
