<script setup>
import { useI18n } from 'vue-i18n';
import LinkButton from '@/components/common/LinkButton.vue';
import routesConstants from '@/constants/routes.constants';

const { t } = useI18n();
</script>

<template>
<section :class="$style.container">
  <h2>{{ t('components.configureServices.title') }}</h2>
  <p class="tac">{{ t('components.configureServices.description') }}</p>
  <LinkButton small class="mt-2" :to="routesConstants.OFFERS">
    {{ t('components.configureServices.goToServicesButton') }}
  </LinkButton>
</section>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
