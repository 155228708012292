<template>
  <h2 class="title">Statistiques</h2>
  <div v-if="pageViews !== null && pageViews !== undefined" class="stats mt-4 mb-4 fade-up">
    <div class="stats__items">
      <div class="stats__card">
        <AnalyticsCard
          :isLoadingData="isLoadingData"
          :leftText="`${pageViews}`"
          rightText="Nombre de consultations de votre entreprise"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
} from 'vue';

import lowcoApi from '@/api/lowco-api';

import AnalyticsCard from './AnalyticsCard.vue';

export default defineComponent({
  name: 'AnalyticsStats',
  components: { AnalyticsCard },
  props: {
    companyId: String,
  },
  setup(props) {
    const { companyId } = toRefs(props);

    const pageViews = ref();
    const isLoadingData = ref(false);

    const loadCompanyPageViews = async () => {
      try {
        isLoadingData.value = true;
        const result = await lowcoApi.getCompanyPageViews(companyId.value);

        if (!result) {
          pageViews.value = 0;
          return;
        }

        pageViews.value = result.viewCount;
      } catch (err) {
        console.log(err);
      } finally {
        isLoadingData.value = false;
      }
    };

    watch(companyId, (value) => {
      if (!value) {
        return;
      }

      loadCompanyPageViews();
    });

    onMounted(() => {
      if (!companyId.value) {
        return;
      }

      loadCompanyPageViews();
    });

    return {
      pageViews,
      isLoadingData,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  font-size: 3rem;
}

.stats {
  font-family: "Francois One", sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 30rem);
  }
}

.fade-up {
  animation: fadeup 0.8s forwards;
}

@keyframes fadeup {
  from {
    opacity: 0;
    transform: translateY(20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
