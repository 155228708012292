<template>
  <Layout :loading="isLoading">
    <div class="container">
      <div class="home">
        <section>
          <h1 class="tac mb-2">{{ t('pages.home.title') }}</h1>
          <p class="tac">{{ t('pages.home.intro') }}</p>
        </section>

        <div class="rowWrapper mt-4">
          <AnnouncementsHightlight v-if="canUseAnnouncements" />
          <ConfigureServices v-else />
          <section>
            <h2>{{ t('pages.home.helpTitle') }}</h2>
            <p class="tac">{{ t('pages.home.helpDescription') }}</p>
            <a
              href="mailto:com@lowco.org"
              class="lowco-button mt-2"
            >
              Contactez-nous
            </a>
            <a class="link mt-2" :href="LOWCO_PWA_LINK" target="_blank">Retour vers le site</a>
          </section>
        </div>

        <Divider class="my-4" />

        <section class="mt-8">
          <AnalyticsStats v-if="currentCompanyId" :companyId="currentCompanyId" />
          <div class="socials">
            <a href="https://www.facebook.com/lowcoapp">
              <FontAwesomeIcon :icon="['fab', 'facebook-f']" />
            </a>
            <a href="https://www.instagram.com/lowco_app">
              <FontAwesomeIcon :icon="['fab', 'instagram']" />
            </a>
            <a href="https://www.youtube.com/channel/UC4GTSe4eGxk1OuBPJxSEZwA">
              <FontAwesomeIcon :icon="['fab', 'youtube']" />
            </a>
          </div>
        </section>
      </div>
    </div>
  </Layout>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import Layout from '@/components/common/Layout.vue';
import Divider from '@/components/common/Divider.vue';
import AnalyticsStats from '@/components/profile/AnalyticsStats.vue';

import useCurrentCompany from '@/composables/useCurrentCompany';
import ConfigureServices from '@/components/home/ConfigureServices.vue';
import config from '@/config';
import useCompanyOffers from '@/composables/useCompanyOffers';
import AnnouncementsHightlight from '@/components/home/AnnouncementsHightlight.vue';

library.add(
  faFacebookF,
  faInstagram,
  faYoutube,
);

export default defineComponent({
  name: 'Home',
  components: {
    FontAwesomeIcon,
    Layout,
    AnalyticsStats,
    Divider,
    ConfigureServices,
    AnnouncementsHightlight,
  },
  setup() {
    const { t } = useI18n();
    const { isFetchingCompanies, currentCompanyId } = useCurrentCompany();
    const { canUseAnnouncements } = useCompanyOffers();

    return {
      t,
      LOWCO_PWA_LINK: config.postLogoutRedirect,
      isLoading: isFetchingCompanies,
      currentCompanyId,
      canUseAnnouncements,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables';
@import '@/assets/styles/common/mixins';

.container {
  background-image:
    linear-gradient(rgba(#fff, 0.8), rgba(#fff, 0.8)),
    url('../assets/images/welcome.svg');
  background-repeat: no-repeat;
  background-position: top center;
}

section {
  margin: 0;
  padding: 0;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcomeImage {
  @include size(30rem);
}

.rowWrapper {
  @include spacing-children('vertical', 2rem);

  @include lg {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  
  @include spacing-children('vertical', 0);
    @include spacing-children('horizontal', 2rem);
    @include evenly-divided(2, 2rem);
  }
}

.socials {
  a {
    font-size: 3rem;

    & + a {
      margin-left: 2rem;
    }
  }
}

.lowco-button {
  display: inline-block;
}

.certification {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 15rem;
    height: 15rem;
  }

  &__download {
    margin: 2rem;
    font-size: 1.5rem;
  }
}
</style>
