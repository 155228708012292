<script setup>
import { useI18n } from 'vue-i18n';

import routesConstants from '@/constants/routes.constants';

import LinkButton from '../common/LinkButton.vue';

const { t } = useI18n();
</script>

<template>
<section :class="$style.container">
  <h2>{{ t('components.announcementsHighlight.title') }}</h2>
  <p class="tac" v-html="t('components.announcementsHighlight.description')" />
  <LinkButton small class="mt-2" :to="routesConstants.ANNOUNCEMENTS">
    {{ t('components.announcementsHighlight.goToAnnouncementsButton') }}
  </LinkButton>
</section>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>